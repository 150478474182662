import me from './memojithumbs.png';
import byu from './byu-logo.png';
import medic from './medic-life-logo.png';
import apple from './apple-logo.png';
import gopuff from './gopuff-logo.png';
import vercel from './vercel-logo.png';
import golang from './golang-logo.png';
import python from './python-logo.png';
import javascript from './javascript-logo.png';
import html from './html-logo.png';
import css from './css-logo.png';
import node from './node-logo.png';
import next from './next-logo.png';
import react from './react-logo.png';
import cpp from './cpp-logo.png';
import ruby from './ruby-logo.png';
import sql from './sql-logo.png';
import dart from './dart-logo.png';
import aws from './aws-logo.png';
import azure from './azure-logo.png';
import docker from './docker-logo.png';
import terraform from './terraform-logo.png';
import gcp from './gcp-logo.png';
import k8s from './k8s-logo.png';
import dd from './dd-logo.png';
import splunk from './splunk-logo.png';
import sf from './sf-logo.png';
import pg from './pg-logo.png';
import mdb from './mdb-logo.png';
import redis from './redis-logo.png';
import res from './Brooke_Mosby_Resume.pdf';
import { SlInfo, SlDoc, SlCalender, SlLike, SlHome, SlSocialLinkedin, SlSocialGithub, SlGraph, SlGameController} from "react-icons/sl";
import Head from 'next/head';
import { InlineWidget } from "react-calendly";
import $ from 'jquery';

import './App.css';

function hidden(id) {
  var x = document.getElementById(id);
  if (x.style.display === "none") {
    x.style.display = "flex";
  } else {
    x.style.display = "none";
  }
}

$(function(){
	boreDOM.init();
	boreDOM.spaceBackground.init();
  var arrow_keys_handler = function (e) {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        e.view.event.preventDefault();
    }
  }
  window.addEventListener("keydown", arrow_keys_handler, false);
});

var boreDOM = {

	upgrade: 'one-canon',

	count: {
		number: 0
	},

	ship: {
		shipId: '#ship',
		bullet: '<div class="bullet">',
		shipSpeed: 5,
		bulletSpeed: 2000
	},

	init: function() {
		this.bindShip();
		this.displayCount();
	},	

	bindShip: function(){
		var area = $('body'),
		    ship = $(this.ship.shipId),
		    speed = this.ship.shipSpeed,
		    maxValue = area.width() - ship.width() + 100,
		    keysPressed = {},
		    distancePerIteration = speed;

		function calculateNewValue(oldValue, keyCode1, keyCode2) {
		    var newValue = parseInt(oldValue, 10)
		                   - (keysPressed[keyCode1] ? distancePerIteration : 0)
		                   + (keysPressed[keyCode2] ? distancePerIteration : 0);
		    return newValue < 0 ? 0 : newValue > maxValue ? maxValue : newValue;
		}

		$(window).keydown(function(event) { 
			if(event.which == 37) {
				$('#ship').css({'background-position':'left'});				
			}	
			if(event.which == 39) {
				$('#ship').css({'background-position':'right'});				
			}

			keysPressed[event.which] = true; 

		});
		$(window).keyup(function(event) { 
			if(event.which == 37 ||event.which == 39 ) {
				$('#ship').css({'background-position':'center'});				
			}	
			keysPressed[event.which] = false; 
		});

		setInterval(function() {			
		    ship.css({
		        left: function(index ,oldValue) {
		            return calculateNewValue(oldValue, 37, 39);
		        },
		        top: function(index, oldValue) {
		            return calculateNewValue(oldValue, 38, 40);
		        }
		    });
		}, 20);
	},


	triggerExplosion: function(topPosition, lefPosition, playerShip){

		var explosionClass = 'explode';

		$('<div class="'+explosionClass+'"><img src="http://andreypokrovskiy.com/image_hosting/boredom/explosion.gif"></div>').appendTo('.sky').each(function(){
			var explosion = $(this);
			explosion.css({left: lefPosition + 50, top: topPosition});

			setTimeout(function(){				
				explosion.remove();
			}, 1700);
		});
	},

	displayCount: function() {
		$('sky').append('<div id="count">'+this.count.number+'</div>');
	}
};

/*
	Draw space background items
========================================*/
(function($) {

	$.extend(boreDOM,{

	  spaceBackground: {

	  	stars: {
				
				asteroid: [{
					div: '<div class="asteroid">',
					speed: 10000, 
					frequency: 5000 
				}],

				planet: [{
					div: '<div class="planet">',
					speed: 60000, 
					frequency: 20000 
				}],

				hugePlanet: [{
					div: '<div class="huge-planet">',
					speed: 40000, 		
					frequency: 30000 
				}],

				expPlanet: [{
					div: '<div class="exp-planet">',
					speed: 60000, 		
					frequency: 400000 
				}],

				moon: [{
					div: '<div class="moon">',
					speed: 20000, 
					frequency: 300000 
				}]
			},			

			init: function(){				
				this.drawSpaceBackground();				
			},

			drawSpaceBackground: function(){
				var stars = this.stars;	

				$.each(stars, function(star, items){
					$.each(items, function(idx, star){				      
              window.setInterval(function(){
                $(star.div).appendTo('.sky').each(function() {	
                  $(this).css({'left': Math.random()*100+'%'});
                  $(this).animate({'top':'110%'}, Math.random()*star.speed).promise().done( function() {  $(this).remove(); });
                });
						  }, star.frequency);
				    });
				});		
			}
	  }
	});
})($);

function App() {
  return (
    <>
      <Head>
        <script type="text/javascript" src="https://code.jquery.com/jquery-1.7.1.min.js"></script>
        <script src="https://assets.calendly.com/assets/external/widget.js"></script>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Head>
      <a name="home"/>
      <div className="App">
        <header className="App-header">
          <img src={me} className="an" alt="logo" />
          <p>
            Brooke Mosby
          </p>
          <div className='spaced-buttons'>
          <a className='button-simple' href="#info"><SlInfo/></a>
          <a className='button-simple' href="#resume"><SlDoc/></a>
          <a className='button-simple' href="#calendar"><SlCalender/></a>
          <a className='button-simple' href="https://www.linkedin.com/in/brooke-mosby-00a57912a"><SlSocialLinkedin/></a>
          <a className='button-simple' href="https://github.com/brookemosby"><SlSocialGithub/></a>
          <a className='button-simple' href="#play"><SlGameController/></a>
          <button className='wiggle' ><SlLike/></button>
          </div>
        </header>
        <body className="App-body">
          <div className='page'>
            <a name="info"/>
            <h2> Infrastructure Engineer & Tech Lead</h2>
            <p className='p'>
              Top performing full-stack senior software engineer with 7+ years of experience
              developing frontend, backend, dev-ops, payment systems, web 3.0 technologies and
              ML/AI R&D, for bullet-proof and agile applications. Excellent interpersonal skills, and
              able to clearly communicate, commit to, and exceed expectations. Skilled in designing
              and implementing robust architecture for many high profile features using a wide range
              of programming languages and tools.
            </p>
            <div className='sub'>
              <button className='subheader' onClick={()=>{hidden("sub1")}} >
                Work
              </button>
              <div className='spaced-buttons-hidden' id="sub1"  style={{display: 'none'}}>
                <a className='button-simple' href="https://acme.byu.edu/">
                  <img className='icons' src={byu} />
                </a>
                <a className='button-simple' href="https://medic.life/">
                  <img className='icons' src={medic} />
                </a>
                <a className='button-simple' href="https://www.apple.com/">
                  <img className='icons' src={apple} />
                </a>
                <a className='button-simple' href="https://www.gopuff.com/">
                  <img className='icons' src={gopuff} />
                </a>
                <a className='button-simple' href="https://vercel.com/">
                  <img className='icons' src={vercel} />
                </a>
              </div>
            </div>
            <div className='sub'>
              <button className='subheader' onClick={()=>{hidden("sub2")}} >
                Code
              </button>
              <div className='spaced-buttons-hidden' id="sub2" style={{display: 'none'}}>
                <a className='button-simple' href="https://go.dev/">
                  <img className='icons' src={golang} />
                </a>
                <a className='button-simple' href="https://www.javascript.com/">
                  <img className='icons' src={javascript} />
                </a>
                <a className='button-simple' href="https://html5.org/">
                  <img className='icons' src={html} />
                </a>
                <a className='button-simple' href="https://www.w3.org/Style/CSS/Overview.en.html">
                  <img className='icons' src={css} />
                </a>
                <a className='button-simple' href="https://nodejs.org/en/">
                  <img className='icons' src={node} />
                </a>
                <a className='button-simple' href="https://nextjs.org/">
                  <img className='icons' src={next} />
                </a>
                <a className='button-simple' href="https://reactjs.org/">
                  <img className='icons' src={react} />
                </a>
                <a className='button-simple' href="https://www.python.org/">
                  <img className='icons' src={python} />
                </a>
                <a className='button-simple' href="https://isocpp.org/">
                  <img className='icons' src={cpp} />
                </a>
                <a className='button-simple' href="https://www.ruby-lang.org/en/">
                  <img className='icons' src={ruby} />
                </a>
                <a className='button-simple' href="https://www.w3schools.com/sql/sql_intro.asp">
                  <img className='icons' src={sql} />
                </a>
                <a className='button-simple' href="https://dart.dev/">
                  <img className='icons' src={dart} />
                </a>
              </div>
            </div>
            <div className='sub'>
              <button className='subheader' onClick={()=>{hidden("sub3")}}>
                Tech
              </button>
              <div className='spaced-buttons-hidden' id="sub3" style={{display: 'none'}}>
                <a className='button-simple' href="https://aws.amazon.com/">
                  <img className='icons' src={aws} />
                </a>
                <a className='button-simple' href="https://azure.microsoft.com/">
                  <img className='icons' src={azure} />
                </a>
                <a className='button-simple' href="https://www.datadoghq.com/">
                  <img className='icons' src={dd} />
                </a>
                <a className='button-simple' href="https://www.docker.com/">
                  <img className='icons' src={docker} />
                </a>
                <a className='button-simple' href="https://www.terraform.io/">
                  <img className='icons' src={terraform} />
                </a>
                <a className='button-simple' href="https://cloud.google.com/gcp">
                  <img className='icons' src={gcp} />
                </a>
                <a className='button-simple' href="https://kubernetes.io/">
                  <img className='icons' src={k8s} />
                </a>
                <a className='button-simple' href="https://www.splunk.com/">
                  <img className='icons' src={splunk} />
                </a>
                <a className='button-simple' href="https://www.snowflake.com/en/data-cloud/platform/">
                  <img className='icons' src={sf} />
                </a>
                <a className='button-simple' href="https://www.postgresql.org/">
                  <img className='icons' src={pg} />
                </a>
                <a className='button-simple' href="https://www.mongodb.com/">
                  <img className='icons' src={mdb} />
                </a>
                <a className='button-simple' href="https://redis.io/">
                  <img className='icons' src={redis} />
                </a>
              </div>
            </div>
            <div className='sub'>
              <button className='subheader' onClick={()=>{hidden("sub4")}}>
                Extra
              </button>
              <div className='spaced-buttons-hidden' id="sub4" style={{display: 'none'}}>
                <a className='button-simple' href="https://brookemosby.github.io/Signal_Analysis/index.html">
                <div><SlGraph/> Signal Analysis</div>
                </a>
              </div>
            </div>
          </div>
          <div className='page'>
            <a name="resume"/>
            <h2> Resume </h2>
            <object data={res} type="application/pdf" width="75%" height="75%">
                <embed src={res} type="application/pdf">
                </embed>
            </object>
          </div>
          <div className='page'>
            <a name="calendar"/>
            <InlineWidget url="https://calendly.com/brookemosby-tech/30min?hide_gdpr_banner=1&background_color=1a1a1a&text_color=ffffff" />
          </div>
          <div className='page'>
            <a name="play"/>
            <div className='game' id="game">
              <div class="sky">
                <div id="ship"></div>
              </div>

            </div>
          </div>
          <div id="footer">
          <a className='button-simple' href="#home"><SlHome/></a>
          </div>
        </body>
      </div>
    </>
  );
}

export default App;
